import type { MenuItem } from "../models/MenuItem"
import type { BreadCrumbItem } from "../models/BreadCrumbItem"

export const MAIN_ROUTE = "Store"

export const breadCrumb_menu: BreadCrumbItem = {
    title: "home.store",
    route: MAIN_ROUTE,
    icon: "home",
    color: "gray-9",
    children: [
        {
            title: "products.title",
            route: "Admin-Products-list",
            icon: "fa-solid fa-tag",
            color: "gray-9",
            children: [
                {
                    title: "products.create",
                    route: "Admin-Products-Create",
                    icon: "add",
                    color: "gray-9",
                },
                {
                    title: "products.edit",
                    route: "Admin-Products-slug-edit",
                    icon: "edit",
                    color: "gray-9",
                },
            ],
        },
        {
            title: "combos.title",
            route: "Admin-combos",
            icon: "fa-solid fa-boxes-stacked",
            color: "gray-9",
            children: [
                {
                    title: "combos.create",
                    route: "Admin-combos-create",
                    icon: "add",
                    color: "gray-9",
                },
                {
                    title: "combos.edit",
                    route: "Admin-combos-id",
                    icon: "edit",
                    color: "gray-9",
                },
            ],
        },
        {
            title: "users.title",
            route: "Admin-Users",
            icon: "group",
            color: "gary-9",
            children: [
                {
                    title: "users.create",
                    route: "Admin-Users-create",
                    icon: "add",
                },
                {
                    title: "users.edit",
                    route: "Admin-Users-id",
                    icon: "edit",
                },
            ],
        },
        {
            title: "roles.title",
            route: "Admin-Roles",
            icon: "verified_user",
            children: [
                {
                    title: "roles.create",
                    route: "Admin-Roles-Create",
                    icon: "add",
                },
                {
                    title: "roles.edit",
                    route: "Admin-Roles-id",
                    icon: "edit",
                },
            ],
        },
        {
            title: "point_of_sales.title",
            route: "Admin-point-of-sales",
            icon: "fa-solid fa-warehouse",
            children: [
                {
                    title: "point_of_sales.create",
                    route: "Admin-point-of-sales-create",
                    icon: "add",
                },
                {
                    title: "point_of_sales.edit",
                    route: "Admin-point-of-sales-id",
                    icon: "edit",
                },
                {
                    title: "point_of_sales.edit-shifts",
                    route: "Admin-point-of-sales-id-shifts",
                    icon: "edit",
                },
            ],
        },
        {
            title: "services.title",
            route: "services.index",
            icon: "handyman",
            children: [
                {
                    title: "services.create",
                    route: "services.create",
                    icon: "add",
                },
                {
                    title: "services.edit",
                    route: "services.edit",
                    icon: "edit",
                },
            ],
        },
        {
            title: "services.title",
            route: "store.services",
            icon: "handyman",
        },
        {
            title: "home.contact_us",
            route: "contact",
            icon: "phone",
        },
        {
            title: "offers.title",
            route: "Admin-Offers",
            icon: "shop",
            children: [
                {
                    title: "offers.create",
                    route: "Admin-Offers-Create",
                    icon: "add",
                },
                {
                    title: "offers.edit",
                    route: "Admin-Offers-id",
                    icon: "edit",
                },
            ],
        },
        {
            title: "testimonials.title",
            route: "Admin-testimonials",
            icon: "shop",
            children: [
                {
                    title: "testimonials.create",
                    route: "Admin-testimonials-create",
                    icon: "add",
                },
                {
                    title: "testimonials.edit",
                    route: "Admin-testimonials-id",
                    icon: "edit",
                },
            ],
        },
        {
            title: "attributes.title",
            route: "attributes.index",
            icon: "extension",
            children: [
                {
                    title: "attributes.create",
                    route: "attributes.create",
                    icon: "add",
                },
                {
                    title: "attributes.edit",
                    route: "attributes.edit",
                    icon: "edit",
                },
            ],
        },
        {
            title: "brands.title",
            route: "Admin-brands",
            icon: "extension",
            children: [
                {
                    title: "brands.create",
                    route: "Admin-brands-create",
                    icon: "add",
                },
                {
                    title: "brands.edit",
                    route: "Admin-brands-id",
                    icon: "edit",
                },
            ],
        },
        {
            title: "blog_categories.title",
            route: "Admin-blogs-categories",
            icon: "extension",
            children: [
                {
                    title: "blog_categories.create",
                    route: "Admin-blogs-categories-create",
                    icon: "add",
                },
                {
                    title: "blog_categories.edit",
                    route: "Admin-blogs-categories-id",
                    icon: "edit",
                },
            ],
        },
        {
            title: "keywords.title",
            route: "Admin-blogs-keywords",
            icon: "extension",
            children: [
                {
                    title: "keywords.create",
                    route: "Admin-blogs-keywords-create",
                    icon: "add",
                },
                {
                    title: "keywords.edit",
                    route: "Admin-blogs-keywords-id",
                    icon: "edit",
                },
            ],
        },
        {
            title: "shoppingCard.sale_in_store",
            route: "sales_buy_card",
            icon: "storefront",
        },
        {
            title: "shoppingCard.my",
            route: "shoppingCard.my",
            icon: "credit_card",
            children: [
                {
                    title: "shoppingCard.recharged_card",
                    route: "shoppingCard.recharge",
                    icon: "request_page",
                },
                {
                    title: "shoppingCard.title",
                    route: "shoppingCard.view",
                    icon: "card_membership",
                },
            ],
        },
        {
            title: "profile.my_account",
            route: "profile.edit",
            icon: "person",
        },
        {
            title: "categories.title",
            route: "Admin-Categories",
            icon: "fa-solid fa-layer-group",
            children: [
                {
                    title: "categories.create",
                    route: "Admin-Categories-Create",
                    icon: "add",
                },
                {
                    title: "categories.edit",
                    route: "Admin-Categories-id",
                    icon: "edit",
                },
            ],
        },
        {
            title: "orders.title",
            route: "Admin-orders",
            icon: "fa-solid fa-cart-shopping",
            children: [
                {
                    title: "orders.create",
                    route: "orders.create",
                    icon: "add",
                },
                {
                    title: "orders.edit",
                    route: "orders.edit",
                    icon: "edit",
                },
                {
                    title: "orders.export",
                    route: "Admin-orders-export",
                    icon: "fas fa-file-export",
                },
            ],
        },
        {
            title: "orders.title",
            route: "orders.my",
            icon: "request_quote",
        },
        {
            title: "invoices.title",
            route: "Admin-invoices",
            icon: "receipt",
            children: [
                {
                    title: "invoices.create",
                    route: "Admin-invoices-Create",
                    icon: "add",
                },
                {
                    title: "invoices.edit",
                    route: "invoices.edit",
                    icon: "edit",
                },
            ],
        },
        {
            title: "reservations.title",
            route: "reservations.index",
            icon: "book_online",
            children: [
                {
                    title: "reservations.create",
                    route: "reservations.create",
                    icon: "add",
                },
                {
                    title: "reservations.edit",
                    route: "reservations.edit",
                    icon: "edit",
                },
            ],
        },
        {
            title: "coins.title",
            route: "Admin-Coins",
            icon: "book_online",
            children: [
                {
                    title: "coins.create",
                    route: "Admin-Coins-create",
                    icon: "add",
                },
                {
                    title: "coins.edit",
                    route: "Admin-Coins-id",
                    icon: "edit",
                },
            ],
        },
        {
            title: "exchange_rates.title",
            route: "Admin-ExchangeRates",
            icon: "book_online",
            children: [
                {
                    title: "exchange_rates.create",
                    route: "Admin-ExchangeRates-create",
                    icon: "add",
                },
                {
                    title: "exchange_rates.edit",
                    route: "Admin-ExchangeRates-id",
                    icon: "edit",
                },
            ],
        },
        {
            title: "reservations.title",
            route: "reservations.my",
            icon: "book_online",
        },
        {
            title: "orderStatuses.title",
            route: "orderStatuses.index",
            icon: "update",
        },
        {
            title: "analytics.visits",
            route: "Admin-Analytics-Visits",
            icon: "group",
        },
        {
            title: "analytics.sales",
            route: "Admin-Analytics-Sales",
            icon: "attach_money",
        },
        {
            title: "analytics.products",
            route: "Admin-Analytics-MostSold",
            icon: "fa-solid fa-sack-dollar",
        },
        {
            title: "settings.generals",
            route: "Admin-Settings-Generals",
            icon: "settings_suggest",
        },
        {
            title: "settings.customization",
            route: "Admin-Settings-Customization",
            icon: "palette",
        },
    ],
}
