<script setup lang="ts">
import HomeNav from "~/components/HomeNav.vue"
import HomeFooter from "~/components/HomeFooter.vue"
import LeftDrawer from "~/components/LeftDrawer.vue"
import { onMounted, ref } from "vue"
import { useLeftMenu } from "~/composables/LefMenu"
import { useNotification } from "~/composables/Notification"
import { QAjaxBar, useQuasar } from "quasar"
import { useMenuItemSelectedStore } from "~/stores/MenuItemSelected"
import BreadCrumbs from "~/components/BreadCrumbs.vue"
import { breadCrumb_menu } from "~/common/crumbs_menu"

/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const router = useRouter()
const leftDrawerOpen = ref(false)
const main_menu_filtered = useLeftMenu()
const menuItemSelectedStore = useMenuItemSelectedStore()
const shopCartStore = useShopCartStore()
const $q = useQuasar()
let timer: NodeJS.Timeout
const initSettingsStore = useDefaultSettingsStore()
const { get } = useOFetchCustom("init-settings")

/**
 *
 *  Life cicle events
 *
 *
 */

onMounted(async () => {
    new Promise(() => {
        get(undefined, {
            onSuccess: (res: any) => {
                initSettingsStore.setSettings(res.data)
            },
        })
    })
    shopCartStore.fetchShopCart()

    const route_name = router.currentRoute.value.name

    if (route_name) {
        menuItemSelectedStore.setByRoute(route_name.toString())
    }
})

/**
 *
 *  Functions section
 *
 *
 */
</script>

<template>
    <q-layout view="hHh lpR fff">
        <q-header elevated>
            <HomeNav></HomeNav>
        </q-header>
        <q-page-container>
            <q-page>
                <div class="tw-w-full max-container">
                    <slot name="breadCrumbs">
                        <BreadCrumbs :crumb="breadCrumb_menu" class="tw-mt-5" />
                    </slot>
                </div>
                <div class="tw-py-5 tw-min-h-screen max-container">
                    <slot></slot>
                </div>
            </q-page>
        </q-page-container>

        <q-footer class="footer">
            <HomeFooter></HomeFooter>
        </q-footer>
    </q-layout>
</template>

<style>
.shadown {
    box-shadow: 0px 4px 4px 0px rgba(6, 116, 191, 0.15);
}
.body-blur {
    filter: blur(3px);
}
</style>
